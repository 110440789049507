<template>
  <div>
    <q-form ref="editForm">
      <c-card title="기본정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable&&confirmEditable&&!disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="target"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-datepicker
              required
              :disabled="disabled"
              :editable="editable&&confirmEditable"
              :start="start"
              :end="end"
              default="today"
              name="checkDate"
              label="점검일"
              v-model="target.checkDate" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-select
              required
              :disabled="disabled"
              :editable="editable&&confirmEditable"
              type="edit"
              codeGroupCd="SOP_FIRE_FIGHTING_ISSUE_RESULT_CD"
              itemText="codeName"
              itemValue="code"
              name="sopFireFightingIssueResultCd"
              label="점검결과"
              v-model="target.sopFireFightingIssueResultCd"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :disabled="disabled"
              :editable="editable && confirmEditable"
              :rows="5"
              label="이슈사항" 
              name="issueContents" 
              v-model="target.issueContents" />
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingIssueCheckTarget',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingIssueCheckTargetId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      target: {
        sopFireFightingIssueCheckTargetId: '',  // 소방 이슈점검 대상 일련번호
        sopFireFightingIssueCheckId: '',  // 소방 이슈점검 일련번호
        sopFireFightingIssueStepCd: '',
        yearmonth: '',
        deptCd: '',
        deptName: '',
        issueCheckName: '',
        sopFireFightingId: '',  // 소방설비 일련번호
        fireFightingName: '',  // 소방설비 일련번호
        sopFireFightingTypeName: '',  // 소방설비 일련번호
        checkDate: '',  // 점검일
        sopFireFightingIssueResultCd: null,  // 이슈점검결과
        issueContents: '',  // 이슈사항
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        regUserIdParent: '',
      },
      editable: true,
      isSave: false,
      detailUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
    requestEditable() {
      return this.target.sopFireFightingIssueStepCd === 'FFI0000001'
        && this.target.regUserIdParent === this.$store.getters.user.userId
    },
    confirmEditable() {
      return this.target.sopFireFightingIssueStepCd === 'FFI0000005'
        && (this.target.deptCd === this.$store.getters.user.deptCd
          || this.target.regUserIdParent === this.$store.getters.user.userId
        )
    },
    completeEditable() {
      return this.target.sopFireFightingIssueStepCd === 'FFI0000010'
        && this.target.regUserIdParent === this.$store.getters.user.userId
    },
    disabled() {
      return this.target.sopFireFightingIssueStepCd === 'FFI0000015'
    },
    start() {
      let yearmonth = this.target.yearmonth;
      return yearmonth ? yearmonth + '-01' : void 0
    },
    end() {
      let yearmonth = this.target.yearmonth;
      let yearmonthDays = yearmonth ? this.$comm.moment(yearmonth).daysInMonth() : '';
      return yearmonth ? yearmonth + '-' + String(yearmonthDays) : void 0
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.fft.fireFightingIssueCheck.target.get.url
      this.saveUrl = transactionConfig.fft.fireFightingIssueCheck.target.update.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingIssueCheckTargetId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (!_result.data.checkDate) {
          _result.data.checkDate = this.$comm.getToday()
        }
        this.$_.extend(this.target, _result.data)

      },);
    },
    saveInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.target.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopFireFightingIssueCheckTargetId = result.data
      this.getDetail();
    },
  }
};
</script>